import { Box, Typography } from "@material-ui/core";

import { getLayout as getSiteLayout } from "@cosy/layouts/Site";

export default function NotFoundPage() {
  return (
    <Box display="flex" flex="1" alignItems="center" justifyContent="center">
      <Box width={600} maxWidth="100%" p={4}>
        <Typography variant="h1">
          Oh no, that page could not be found.
        </Typography>
      </Box>
    </Box>
  );
}

NotFoundPage.getLayout = getSiteLayout;
NotFoundPage.authRequired = false;
