import {
  MenuItem,
  Select,
  Chip,
  Input,
  FormHelperText,
  FormControl,
  ListSubheader,
  FormLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";

import logger from "@cosy/lib/logger";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

export default function BlockInputMultiStaticSelect({ block }) {
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const { element, label, optional } = block;
  const [canOpen, setCanOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const { action_id, initial_options } = element;
    if (initial_options) {
      setValue(
        action_id,
        initial_options.map((option) => option.value)
      );
    }
  }, [element, setValue]);

  if (!element.options) {
    logger.error("Can not render BlockInputMultiStaticSelect without options");
    return null;
  }

  const selectHandleOnOpen = () => {
    setCanOpen(!canOpen);
  };

  const error = errors[element?.action_id];

  return (
    <Controller
      render={({ field: { value, onChange, ...fieldProps } }) => (
        <FormControl error={!!error} className={classes.formControl}>
          {block.label?.text && (
            <FormLabel component="legend">{block.label.text}</FormLabel>
          )}
          <Select
            value={value || []}
            multiple
            size="medium"
            onClick={selectHandleOnOpen}
            onChange={(e) => {
              const options = e.target.value;
              onChange(options.length ? options : undefined);
            }}
            open={canOpen}
            input={<Input />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((item) => (
                  <Chip
                    key={item}
                    size="small"
                    label={
                      element.options.find((option) => option.value === item)
                        .text.text
                    }
                    className={classes.chip}
                    onDelete={() => {
                      const options = value?.filter((i) => i !== item);
                      setValue(
                        element.action_id,
                        options.length ? options : undefined
                      );
                    }}
                  />
                ))}
              </div>
            )}
            {...fieldProps}
          >
            {optional && <MenuItem value="">-</MenuItem>}
            {element.option_groups?.length > 0
              ? element.option_groups.map((group) => [
                  <ListSubheader key={group.label.text}>
                    {group.label.text}
                  </ListSubheader>,
                  group.options?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text.text}
                    </MenuItem>
                  )),
                ])
              : element.options?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text.text}
                  </MenuItem>
                ))}
          </Select>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
      // defaultValue={}
      name={element?.action_id}
      control={control}
      rules={{
        required: !optional ? `${label.text} is required.` : false,
      }}
    />
  );
}
