import { Avatar } from "@material-ui/core";
import BoringAvatar from "boring-avatars";

import { brand, secondary } from "@cosy/lib/constants/colors";

export default function UserAvatar({ user, size = 44, variant }) {
  const avatarUrl = user?.avatar?.cloudinaryData?.secure_url;

  if (avatarUrl) {
    return (
      <Avatar
        style={{
          width: size,
          height: size,
        }}
        alt={`${user.displayName} avatar`}
        src={avatarUrl}
        variant={variant}
      />
    );
  }

  return (
    <BoringAvatar
      size={size}
      name={user?.email}
      variant="beam"
      colors={[
        brand.primary,
        brand.honeyGold,
        brand.skyBlue,
        brand.cerise,
        secondary.pistachio,
        secondary.other,
      ]}
    />
  );
}
