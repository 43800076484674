import { SUB_TYPES } from "./results";

export const INITIAL_SEARCH_PARAMS = {
  hitsPerPage: 7,
  filters: "NOT isArchived:true",
  page: 0,
  facetFilters: [],
  attributesToRetrieve: [
    "*",
    "-distinctID",
    "-_cosy",
    "-queries",
    "-successfulQueries",
    "-text",
  ],
};

export const INITIAL_SEARCH_RESULTS = {
  exhaustiveNbHits: true,
  hits: [],
  hitsPerPage: 7,
  index: process.env.NEXT_PUBLIC_ALGOLIA_INDEX,
  nbHits: 0,
  nbPages: 1,
  page: 0,
  params: "",
  processingTimeMS: 0,
  query: "",
};

export const DEFAULT_ANSWERS_OPTIONS = {
  attributesForPrediction: ["text"],
  semanticHighlight: true,
  threshold: 140,
  returnExtractAttribute: false,
  nbHits: 1,
  searchParameters: {
    facetFilters: [
      [`subType:${SUB_TYPES.DOCUMENT}`, `subType:${SUB_TYPES.PAGE}`],
    ],
  },
};

export const ANSWERS_LANGUAGES = ["en"];
