import { useAsync } from "react-use";

import useAlgoliaIndex from "@cosy/hooks/use_algolia_index";
import useSearchState from "@cosy/hooks/use_search_state";
import {
  INITIAL_SEARCH_RESULTS,
  ANSWERS_LANGUAGES,
  DEFAULT_ANSWERS_OPTIONS,
} from "@cosy/lib/constants/algolia";
import logger from "@cosy/lib/logger";

const _MIN_CHARS_FOR_ANSWER_SEARCH = 3;

const _EXCELLENT_HIGHLIGHT_SCORE = 0.1;
const _EXCELLENT_SCORE = 500;
const _MINIMUM_HIGHLIGHT_SCORE = 0.025;

/**
 * Hook for components to get Algolia answers.
 *
 * @returns {object}
 */
export default function useAlgoliaAnswers() {
  const index = useAlgoliaIndex();
  const { query } = useSearchState();

  const { value, loading } = useAsync(async () => {
    if (!index) {
      logger.debug("No search index present, not searching.");
      return;
    }

    if (query.length < _MIN_CHARS_FOR_ANSWER_SEARCH) {
      logger.debug("Serving blank default results");
      return { ...INITIAL_SEARCH_RESULTS, query };
    }

    logger.debug("Running answers search “%s”", query);

    const options = {
      ...DEFAULT_ANSWERS_OPTIONS,
      searchParameters: {
        ...DEFAULT_ANSWERS_OPTIONS.searchParameters,
        optionalWords: query,
      },
    };

    const result = await index.findAnswers(query, ANSWERS_LANGUAGES, options);

    if (result?.hits.length > 0) {
      logger.info(
        "Found answer for “%s” in %dms",
        query,
        result.processingTimeMS,
        result.hits[0]._answer
      );
    }

    const { semanticHighlightScore, score } = result.hits[0]._answer;

    if (
      semanticHighlightScore > _MINIMUM_HIGHLIGHT_SCORE &&
      (semanticHighlightScore > _EXCELLENT_HIGHLIGHT_SCORE ||
        score > _EXCELLENT_SCORE)
    ) {
      return result;
    }
  }, [index, query]);

  return {
    hits: value?.hits || INITIAL_SEARCH_RESULTS.hits,
    isLoading: loading,
  };
}
