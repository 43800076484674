import algoliasearch from "algoliasearch/lite";
import { useState, useEffect, useContext, useMemo, createContext } from "react";

import useMe from "./use_me";
import useSlug from "./use_slug";

const AlgoliaIndexContext = createContext();

/**
 * Hook for components to get search index from parent Provider.
 *
 * @returns {*}
 */
export default function useAlgoliaIndex() {
  return useContext(AlgoliaIndexContext);
}

/**
 * Provider component that makes search index available
 * to any children that calls useAlgoliaIndex().
 *
 * @param {object} props
 * @param {*} props.children
 * @returns {*}
 */
export function AlgoliaIndexProvider({ children }) {
  const algoliaIndex = _useProvideAlgoliaIndex();
  return (
    <AlgoliaIndexContext.Provider value={algoliaIndex}>
      {children}
    </AlgoliaIndexContext.Provider>
  );
}

/**
 * Provider hook that handles Algolia api keys and client updates,
 * returning the proper search index to be used.
 *
 * @returns {*}
 */
function _useProvideAlgoliaIndex() {
  const algoliaApiKey = _useAlgoliaApiKey();
  const [client, setClient] = useState(() => _getAlgoliaClient(algoliaApiKey));

  const index = useMemo(
    () => client?.initIndex(process.env.NEXT_PUBLIC_ALGOLIA_INDEX),
    [client]
  );

  useEffect(() => {
    setClient(_getAlgoliaClient(algoliaApiKey));
  }, [algoliaApiKey]);

  useEffect(() => {
    if (client) {
      client.clearCache();
    }
  }, [client]);

  return index;
}

/**
 * Hook that returns current's membership Algolia apiKey.
 *
 * @returns {(string|null)}
 */
function _useAlgoliaApiKey() {
  const { me } = useMe();
  const slug = useSlug();
  const [apiKey, setApiKey] = useState(() =>
    _getCurrentMembershipSearchApiKey(me, slug)
  );

  useEffect(() => {
    setApiKey(_getCurrentMembershipSearchApiKey(me, slug));
  }, [slug, me]);

  return apiKey;
}

/**
 * @private
 */
function _getAlgoliaClient(searchApiKey) {
  if (!searchApiKey) {
    return null;
  }

  return algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
    searchApiKey
  );
}

/**
 * @private
 */
function _getCurrentMembershipSearchApiKey(user, slug) {
  return user?.memberships.find((m) => m.workspace.slug === slug)?.searchApiKey;
}
