import { useState, useEffect, useCallback } from "react";
import { useAsync } from "react-use";

import useAlgoliaIndex from "@cosy/hooks/use_algolia_index";
import useSearchState from "@cosy/hooks/use_search_state";
import { INITIAL_SEARCH_RESULTS } from "@cosy/lib/constants/algolia";
import logger from "@cosy/lib/logger";

/**
 * Hook for components to get Algolia search results.
 *
 * @param {object} answers - The SearchSet for Algolia answers.
 * @returns {object}
 */
export default function useAlgoliaResults(answers) {
  const index = useAlgoliaIndex();
  const { query, isBrowseMode, searchParams } = useSearchState();

  const { value, loading } = useAsync(async () => {
    if (!index) {
      logger.debug("No search index present, not searching.");
      return;
    }

    if (!query.length && !isBrowseMode) {
      logger.debug("Serving blank default results");
      return { ...INITIAL_SEARCH_RESULTS, query };
    }

    logger.debug("Running search “%s” %o", query, searchParams);
    return index.search(query, searchParams);
  }, [index, query, isBrowseMode, searchParams]);

  // We keep a mutable version of the response because we need
  // to be able to mutate hits (via updateHit).
  const [response, setResponse] = useState(INITIAL_SEARCH_RESULTS);

  // Keep mutable version up to date with the async response.
  useEffect(() => {
    if (value) {
      setResponse(value);
    }
  }, [value]);

  /**
   * Takes new result data and merges it into the current response, based on its
   * objectID.
   *
   * @param {object} updatedHit
   * @param {string} updatedHit.objectID - Required to match an existing result.
   * This function is a no-op if there's no match.
   */
  const updateHit = useCallback(
    (updatedHit) => {
      setResponse((response) => {
        const match = response.hits?.find(
          (hit) => hit.objectID === updatedHit.objectID
        );
        if (match) {
          Object.assign(match, updatedHit);
        }
        return { ...response };
      });
    },
    [setResponse]
  );

  // De-duplicate results which are the featured answer
  const hits = response.hits.filter(
    (hit) => !answers?.hits.some(({ objectID }) => objectID === hit.objectID)
  );

  return {
    hits,
    isLoading: loading,
    totalPages: response.nbPages,
    updateHit,
  };
}
