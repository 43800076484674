/**
 * @file React hook for holding and updating the currently focused search result
 * row. Tracks both the currently focused Hit and SearchSet, and provides
 * navigation methods to move between sets or horizontally within a horizontal
 * set.
 */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";

import useSearchSets from "@cosy/hooks/use_search_sets";

/**
 * @typedef HitsFocusModelContextValues
 * @property {number} focusedSet - The set which contains the currently focused
 * hit.
 * @property {object} focusedHit - The full object of the currently focused
 * search result row.
 * @property {Function} setFocusedHit - A function to change the currently focused hit.
 * @property {Function} up - Move up one hit or set, depending on the currently focused item.
 * @property {Function} down - Move down one hit or set, depending on the currently focused item.
 * @property {Function} left - Move left one hit, in a horizontal set.
 * @property {Function} right - Move right one hit, in a horizontal set.
 */

const HitsFocusModelContext = createContext();

// We leave extra room on the top for the floating search input
const _SCROLL_PADDING_TOP = 100;
const _SCROLL_PADDING_BOTTOM = 20;

/**
 * React Provider which keeps the currently focused hit and changes the focused hit.
 *
 * @param {object} props
 * @param {*} props.children
 * @returns {*}
 */
export function HitsFocusModelProvider({ children }) {
  const { sets } = useSearchSets();
  const [focusedHit, setFocusedHit] = useState();
  // We only want to navigate between sets which contain results
  const setsWithResults = useMemo(
    () => Object.values(sets).filter(({ hits }) => hits?.length > 0),
    [sets]
  );

  // We use a Map to associate hits with DOM refs, to ensure focused hits are
  // in the current window view
  const [refsMap, setRefsMap] = useState(new Map());
  const setHitRef = useCallback((hit, ref) => {
    setRefsMap((currentRefsMap) => {
      currentRefsMap.set(hit, ref);
      return new Map(currentRefsMap);
    });
  }, []);
  const deleteHitRef = useCallback((hit) => {
    setRefsMap((currentRefsMap) => {
      currentRefsMap.delete(hit);
      return new Map(currentRefsMap);
    });
  }, []);

  // Calculate the currently focused set
  const focusedSet = useMemo(
    () => setsWithResults.find((s) => s.hits.includes(focusedHit)),
    [setsWithResults, focusedHit]
  );

  // Reset the current focus if setsWithResults changes
  useEffect(() => {
    if (setsWithResults.length > 0) {
      const [firstSet] = setsWithResults;
      setFocusedHit(firstSet.hits?.[0]);
    } else {
      setFocusedHit(null);
    }
  }, [setsWithResults]);

  const setFocusAndScrollIntoView = useCallback(
    (hit) => {
      setFocusedHit(hit);
      const focusedRef = refsMap.get(hit);
      if (focusedRef) {
        _scrollIntoView(focusedRef);
      }
    },
    [setFocusedHit, refsMap]
  );

  const up = useCallback(() => {
    const isFirstInSetOrHorizontal =
      focusedSet.hits.indexOf(focusedHit) === 0 || !!focusedSet.isHorizontal;
    const setIndex = setsWithResults.indexOf(focusedSet);
    const isFirstSet = setIndex === 0;

    if (!isFirstInSetOrHorizontal) {
      // Move up one hit
      const hitIndex = focusedSet.hits.indexOf(focusedHit);
      setFocusAndScrollIntoView(focusedSet.hits[hitIndex - 1]);
    } else if (!isFirstSet) {
      // Move up one set
      const previousSet = setsWithResults[setIndex - 1];
      // If moving up into a set that’s horizontal, focus the first hit,
      // otherwise focus the bottom hit (since we’re navigating up)
      setFocusAndScrollIntoView(
        previousSet.hits[
          previousSet.isHorizontal ? 0 : previousSet.hits.length - 1
        ]
      );
    }
  }, [focusedSet, focusedHit, setsWithResults, setFocusAndScrollIntoView]);

  const down = useCallback(() => {
    const isLastInSetOrHorizontal =
      focusedSet.hits?.[focusedSet.hits.length - 1] === focusedHit ||
      !!focusedSet.isHorizontal;
    const setIndex = setsWithResults.indexOf(focusedSet);
    const isLastSet = setIndex === setsWithResults.length - 1;

    if (!isLastInSetOrHorizontal) {
      // Move down one hit
      const hitIndex = focusedSet.hits.indexOf(focusedHit);
      setFocusAndScrollIntoView(focusedSet.hits[hitIndex + 1]);
    } else if (!isLastSet) {
      // Move down one set
      const nextSet = setsWithResults[setIndex + 1];
      setFocusAndScrollIntoView(nextSet.hits[0]);
    }
  }, [focusedSet, focusedHit, setsWithResults, setFocusAndScrollIntoView]);

  const right = useCallback(() => {
    const currentIndex = focusedSet.hits.indexOf(focusedHit);
    if (
      !focusedSet.isHorizontal ||
      currentIndex === focusedSet.hits.length - 1
    ) {
      return;
    }

    setFocusAndScrollIntoView(focusedSet.hits[currentIndex + 1]);
  }, [focusedSet, focusedHit, setFocusAndScrollIntoView]);

  const left = useCallback(() => {
    const currentIndex = focusedSet.hits.indexOf(focusedHit);
    if (!focusedSet.isHorizontal || currentIndex === 0) {
      return;
    }
    setFocusAndScrollIntoView(focusedSet.hits[currentIndex - 1]);
  }, [focusedSet, focusedHit, setFocusAndScrollIntoView]);

  return (
    <HitsFocusModelContext.Provider
      value={{
        up,
        down,
        right,
        left,
        focusedHit,
        focusedSet,
        setFocusedHit,
        setHitRef,
        deleteHitRef,
      }}
    >
      {children}
    </HitsFocusModelContext.Provider>
  );
}

/**
 * React Hook for accessing the HitsFocusModelContext.
 *
 * @returns {HitsFocusModelContextValues}
 */
export default function useHitsFocusModel() {
  return useContext(HitsFocusModelContext);
}

/**
 * React hook which takes a hit, and returns whether or not it's focused and a
 * ref to associate with a DOM element (to scroll it into view).
 *
 * @param {!object} hit
 * @returns {object}
 */
export function useFocusableHit(hit) {
  const ref = useRef();
  const { focusedHit, setHitRef, deleteHitRef } = useHitsFocusModel();

  // Associate the created ref with the provided hit. Used in to scroll the hit
  // into view when the user uses keyboard navigation.
  useEffect(() => {
    setHitRef(hit, ref);
    return () => deleteHitRef(hit);
  }, [setHitRef, hit, deleteHitRef]);

  const isFocused = focusedHit === hit;
  return { isFocused, ref };
}

/**
 * Takes a React ref which holds a DOM element (corresponding to a hit) and
 * scrolls it into the viewport.
 *
 * @private
 */
function _scrollIntoView({ current: el }) {
  const box = el?.getBoundingClientRect();
  if (!box) {
    return;
  }

  if (box.top < 0) {
    window.scrollTo({
      top: box.top - _SCROLL_PADDING_TOP + window.scrollY,
      behavior: "smooth",
    });
  }

  if (
    box.bottom > (window.innerHeight || document.documentElement.clientHeight)
  ) {
    window.scrollTo({
      top:
        box.bottom -
        window.innerHeight +
        _SCROLL_PADDING_BOTTOM +
        window.scrollY,
      behavior: "smooth",
    });
  }
}
