/**
 * @file React hook for navigating search results via keyboard, using
 * hotkeys.js.
 * @see https://wangchujiang.com/hotkeys/
 */
import hotkeys from "hotkeys-js";
import { useEffect } from "react";

import useHitsFocusModel from "@cosy/hooks/use_hits_focus_model";
import useHotKeys from "@cosy/hooks/use_hotkeys";
import usePerformAction from "@cosy/hooks/use_perform_action";
import useSearchSets from "@cosy/hooks/use_search_sets";
import useSearchState from "@cosy/hooks/use_search_state";
import logger from "@cosy/lib/logger";

/**
 * React Hook which adds keyboard navigation to the search results list.
 *
 * @returns {object}
 */
export default function useKeyboardNav() {
  const { setQuery } = useSearchState();
  const { sets } = useSearchSets();
  const { focusedHit, focusedSet, down, up, left, right } = useHitsFocusModel();
  const performAction = usePerformAction(focusedSet === sets.fallback);

  useHotKeys(
    "down",
    (e) => {
      e.preventDefault();
      down();
    },
    "searchInputFocused"
  );

  useHotKeys(
    "up",
    (e) => {
      e.preventDefault();
      up();
    },
    "searchInputFocused"
  );

  useHotKeys(
    "right",
    (e) => {
      if (focusedSet.isHorizontal) {
        e.preventDefault();
        right();
      }
    },
    "searchInputFocused"
  );

  useHotKeys(
    "left",
    (e) => {
      if (focusedSet.isHorizontal) {
        e.preventDefault();
        left();
      }
    },
    "searchInputFocused"
  );

  useHotKeys(
    "enter",
    (e) => {
      e.preventDefault();
      // Temporarily disabling hitting "enter" on people results, as their
      // detail pages aren't ready
      if (focusedHit && focusedSet !== sets.people) {
        performAction(focusedHit, true);
      }
    },
    "searchInputFocused"
  );

  useHotKeys(
    "escape",
    (e) => {
      e.preventDefault();
      setQuery("");
    },
    "searchInputFocused"
  );

  // Turn on/off keyboard nav features on input focus/blur.
  const onFocusChange = (isFocused) => {
    const newScope = isFocused ? "searchInputFocused" : "searchInputBlurred";
    logger.debug("Focus change, setting hotkeys scope: %s", newScope);
    hotkeys.setScope(newScope);
  };

  useEffect(() => {
    hotkeys.setScope("searchInputFocused");
    return () => hotkeys.setScope("all");
  }, []);

  return { onFocusChange };
}
