/**
 * Returns a new url with the correct query.
 *
 * @param {string} url
 * @param {string} query
 * @param {string} keyword
 * @returns {string}
 */
export function replaceQuery(url, query, keyword) {
  return url.replace(
    "{query}",
    encodeURIComponent(_replaceKeyword(query, keyword))
  );
}

/**
 * @private
 */
function _replaceKeyword(input, keyword) {
  return input.replace(new RegExp("^" + keyword + " *"), "");
}
