export const brand = {
  primary: "#ff1559",
  honeyGold: "#ffb300",
  skyBlue: "#0086dd",
  cerise: "#f029b7",
};

export const secondary = {
  pistachio: "#90be6d",
  other: "#8338ec",
};
