import { Divider } from "@material-ui/core";

import BlockContext from "./BlockContext";
import BlockHeader from "./BlockHeader";
import BlockImage from "./BlockImage";
import BlockInput from "./BlockInput";
import BlockSection from "./BlockSection";

// Add top-level form control/submission(?)
const BLOCK_COMPONENTS = {
  context: BlockContext,
  section: BlockSection,
  header: BlockHeader,
  image: BlockImage,
  input: BlockInput,
  divider: function BlockDivider() {
    return <Divider />;
  },
};

export default function Blocks({ blocks, optionsLoadURL, handleInteraction }) {
  if (!blocks) {
    return null;
  }

  const autoFocusIndex = blocks.findIndex((b) => b.type === "input");
  return (
    <div>
      {blocks.map((block, i) => {
        if (Object.hasOwnProperty.call(BLOCK_COMPONENTS, block.type)) {
          const BlockComponent = BLOCK_COMPONENTS[block.type];
          return (
            <BlockComponent
              key={i}
              block={block}
              optionsLoadURL={optionsLoadURL}
              handleInteraction={handleInteraction}
              autoFocus={autoFocusIndex === i}
            />
          );
        }

        return null;
      })}
    </div>
  );
}
