import { useTheme } from "@material-ui/styles";

import HitIcon from "./HitIcon";

export default function SearchInputHitIcon({ hit }) {
  const theme = useTheme();

  if (!hit) {
    return null;
  }
  return (
    <div style={{ paddingRight: theme.spacing(2) }}>
      <HitIcon hit={hit} />
    </div>
  );
}
