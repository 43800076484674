import Grid from "@material-ui/core/Grid";

import Markdown from "./Markdown";

import { getCloudinaryURL } from "@cosy/utils/cloudinary";

export default function BlockContext({ block }) {
  if (!block.elements) {
    return "";
  }
  return (
    <Grid container wrap="nowrap" alignItems="center" spacing={1}>
      {block.elements.map((el, i) => {
        if (el.type === "image") {
          const cloudinaryURL = getCloudinaryURL(el._cloudinary_data, [
            "SmallIcon",
          ]);
          const src = cloudinaryURL || el.image_url;

          return (
            <Grid key={i} item>
              <img
                src={src}
                height="24"
                width="auto"
                style={{ display: "block" }}
                alt={el.alt_text}
              />
            </Grid>
          );
        } else if (el.type === "mrkdwn") {
          return (
            <Grid key={i} item>
              <Markdown
                color="textSecondary"
                variant="overline"
                text={el.text}
                inline
              />
            </Grid>
          );
        } else {
          return "";
        }
      })}
    </Grid>
  );
}
