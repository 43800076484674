export const SUB_TYPES = {
  DOCUMENT: "Document",
  IMAGE: "Image",
  EVENT: "Event",
  ISSUE: "Issue",
  PERSON: "Person",
  POST: "Post",
  PULL_REQUEST: "Pull request",
  PAGE: "Page",
  REPO: "Repository",
  DIRECTORY: "Directory",
  PRESENTATION: "Presentation",
  COMPANY: "Company",
};

export const CUSTOM_SUB_TYPES = {
  BOOKMARK: "Bookmark",
};

export const SUB_TYPE_ALIASES = {
  [SUB_TYPES.DOCUMENT]: ["docs", "documents", "files", "pages"],
  [SUB_TYPES.REPO]: ["repos", "repositories"],
  [SUB_TYPES.ISSUE]: ["issues", "bugs"],
  [SUB_TYPES.PULL_REQUEST]: ["pull requests", "prs"],
  [SUB_TYPES.DIRECTORY]: ["folders"],
  [SUB_TYPES.PRESENTATION]: ["presentations", "decks", "slides"],
};

export const SUB_TYPES_PLURALIZED = {
  [SUB_TYPES.DOCUMENT]: "Documents",
  [SUB_TYPES.IMAGE]: "Images",
  [SUB_TYPES.EVENT]: "Events",
  [SUB_TYPES.ISSUE]: "Issues",
  [SUB_TYPES.PERSON]: "People",
  [SUB_TYPES.POST]: "Posts",
  [SUB_TYPES.PULL_REQUEST]: "Pull requests",
  [SUB_TYPES.PAGE]: "Pages",
  [SUB_TYPES.REPO]: "Repositories",
  [SUB_TYPES.DIRECTORY]: "Directories",
  [SUB_TYPES.COMPANY]: "Companies",
  [SUB_TYPES.PRESENTATION]: ["Presentations"],
  [CUSTOM_SUB_TYPES.BOOKMARK]: "Bookmarks",
};

export const TYPES = {
  URL: "url",
  ACTION: "action",
};

export const ACTION_SUB_TYPES = {
  // Replaces the current query
  AUTOCOMPLETE: "Autocomplete",

  // Opens a URL
  WEB_SEARCH: "Web search",

  // Opens a modal
  MODAL: "Modal",
};

export const MAX_INITIAL_INDEX_RESULTS = 500;

export const MAX_SEARCH_RESULTS = 20;

export const VISIBILITY_SCOPE_TYPES = {
  SEARCH_SOURCE: "SearchSource",
  MEMBERSHIP: "Membership",
  WORKSPACE: "Workspace",
};

export const FACETS = {
  SUB_TYPE: "subType",
  SOURCE: "source",
};
