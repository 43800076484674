import { useRouter } from "next/router";

import useSnackbar from "./use_snackbar";

import useSearchSets from "@cosy/hooks/use_search_sets";
import { post } from "@cosy/utils/api";

/**
 * React hook for handling user interactions on interactive integration
 * components, like buttons and form elements. Send the event to the
 * integration, and can optionally show a Snackbar or update an Algolia result
 * from the response.
 *
 * @returns {Function}
 */
export default function usePostInteraction() {
  const {
    sets: {
      results: { updateHit },
    },
  } = useSearchSets();
  const { openSnackbar } = useSnackbar();
  const router = useRouter();

  const postInteraction = async function (hit, action) {
    // We added visibility scope to support custom results so new results won't have searchSource.
    const searchSource = hit.visibility.scopeID;
    const { data } = await post(
      `/workspaces/${router.query.slug}/sources/${searchSource}/interaction`,
      { hit, actions: [action] }
    );
    data?.result && updateHit({ objectID: hit.objectID, ...data.result });
    data?.message && openSnackbar(data.message);
  };

  return postInteraction;
}
