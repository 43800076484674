import { Box, Button, Divider, ListSubheader, Menu } from "@material-ui/core";
import {
  AddBoxRounded,
  MeetingRoomRounded,
  ModeComment,
  WebAsset,
  Explore,
} from "@material-ui/icons";
import { useRouter } from "next/router";
import { useState, useEffect, useRef, useCallback, forwardRef } from "react";
import { useIntercom } from "react-use-intercom";

import InternalMenuItem from "@cosy/components/InternalMenuItem";
import UserAvatar from "@cosy/components/UserAvatar";
import WorkspaceAvatar from "@cosy/components/WorkspaceAvatar";
import useMe from "@cosy/hooks/use_me";
import useWorkspace from "@cosy/hooks/use_workspace";
import { CHROME_EXTENSION_URL } from "@cosy/lib/constants/chrome_extension";
import internalHref from "@cosy/lib/formatters/internal-href";
import { isExcludedFromAnalytics } from "@cosy/utils/analytics";
import apiHostPrefix from "@cosy/utils/api_host_prefix";

const _ROADMAP_URL =
  "https://portal.productboard.com/getcosy/1-cosy-public-roadmap";

export default function UserMenu() {
  const router = useRouter();
  const { me } = useMe();
  const { workspace } = useWorkspace();
  const triggerEl = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const otherWorkspaces = me?.memberships
    ?.map((m) => m.workspace)
    .filter((w) => w._id !== workspace?._id);

  const onClose = () => setIsOpen(false);

  return (
    <div>
      <UserButton user={me} ref={triggerEl} onClick={() => setIsOpen(true)} />
      <Menu
        anchorEl={triggerEl.current}
        keepMounted
        open={isOpen}
        onClose={onClose}
      >
        <PlanSummary workspace={workspace} />
        <WorkspaceList
          workspaces={otherWorkspaces}
          onWorkspaceChange={onClose}
        />
        <MenuItem
          primary="Create a new workspace…"
          icon={AddBoxRounded}
          onClick={() => router.push(internalHref("/onboarding"))}
        />
        <MenuItem
          icon={WebAsset}
          href={CHROME_EXTENSION_URL}
          primary="Chrome extension"
        />
        <FeedbackMenuItem onClick={onClose} me={me} />
        <MenuItem icon={Explore} href={_ROADMAP_URL} primary="Roadmap" />
        <MenuItem
          icon={MeetingRoomRounded}
          onClick={() => router.push(`${apiHostPrefix}/api/logout`)}
          primary="Log out"
        />
      </Menu>
    </div>
  );
}

const UserButton = forwardRef(function UserButtonWithRef(
  { user, onClick },
  ref
) {
  return (
    <Button
      aria-label="more"
      aria-controls="user-menu"
      aria-haspopup="true"
      data-testid="userMenuButton"
      onClick={onClick}
      style={{ padding: 8 }}
      ref={ref}
    >
      <UserAvatar user={user} />
    </Button>
  );
});

function PlanSummary({ workspace }) {
  if (!workspace) {
    return null;
  }

  return (
    <>
      <Box p={1}>
        <Box display="flex">
          <WorkspaceAvatar workspace={workspace} size={48} />
          <Box ml={2} maxWidth={200}>
            Your workspace <strong>{workspace.name}</strong> is on the{" "}
            <strong>{workspace.plan?.toLowerCase()}</strong> plan.
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
}

function WorkspaceList({ workspaces = [], onWorkspaceChange }) {
  const router = useRouter();

  if (workspaces.length === 0) {
    return null;
  }

  return (
    <>
      <ListSubheader key="workspaces-header">Workspaces</ListSubheader>
      {workspaces.map((workspace, i) => (
        <MenuItem
          icon={WorkspaceAvatar}
          iconProps={{
            workspace,
            size: 24,
          }}
          primary={workspace.name}
          onClick={() => {
            onWorkspaceChange && onWorkspaceChange();
            router.push(internalHref(`/${workspace.slug}`));
          }}
          key={i}
        />
      ))}
      <Divider key="workspaces-divider" />
    </>
  );
}

function MenuItem({ icon, iconProps, primary, secondary, onClick, href }) {
  return (
    <InternalMenuItem
      onClick={onClick}
      aria-label={primary}
      href={href}
      component={href ? "a" : "li"}
      iconComponent={icon}
      iconProps={iconProps}
      text={primary}
      secondaryText={secondary}
    />
  );
}

function FeedbackMenuItem({ me, onClick }) {
  const { boot, show } = useIntercom();

  useEffect(() => {
    if (me && !isExcludedFromAnalytics(me.email)) {
      boot({
        name: me.displayName,
        email: me.email,
        user_id: me._id,
        hide_default_launcher: true,
      });
    }
  }, [boot, me]);

  const showIntercom = useCallback(() => {
    show();
  }, [show]);

  if (!me) {
    return null;
  }

  return (
    <MenuItem
      icon={ModeComment}
      primary="Feedback"
      onClick={() => {
        onClick();
        showIntercom();
      }}
      role="button"
    />
  );
}
