import { Box } from "@material-ui/core";

import BlockInputCheckboxes from "./BlockInputCheckboxes";
import BlockInputExternalSelect from "./BlockInputExternalSelect";
import BlockInputMultiStaticSelect from "./BlockInputMultiStaticSelect";
import BlockInputPlainText from "./BlockInputPlainText";
import BlockInputStaticSelect from "./BlockInputStaticSelect";

export function BlockInputElement({
  block,
  optionsLoadURL,
  autoFocus,
  handleInteraction,
}) {
  if (block.element?.type === "external_select") {
    return (
      <BlockInputExternalSelect
        block={block}
        optionsLoadURL={optionsLoadURL}
        handleInteraction={handleInteraction}
      />
    );
  } else if (block.element?.type === "checkboxes") {
    return <BlockInputCheckboxes block={block} />;
  } else if (block.element?.type === "static_select") {
    return (
      <BlockInputStaticSelect
        block={block}
        handleInteraction={handleInteraction}
      />
    );
  } else if (block.element?.type === "multi_static_select") {
    return (
      <BlockInputMultiStaticSelect
        block={block}
        handleInteraction={handleInteraction}
      />
    );
  }

  return <BlockInputPlainText block={block} autoFocus={autoFocus} />;
}

export default function BlockInput({ ...props }) {
  return (
    <Box my={1}>
      <BlockInputElement {...props} />
    </Box>
  );
}
