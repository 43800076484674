import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";
import isEqual from "lodash/isEqual";
import { useFormContext } from "react-hook-form";

export default function BlockInputCheckboxes({ block }) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{block.label.text}</FormLabel>
      <FormGroup>
        {block.element.options.map((option) => (
          <CheckboxOption block={block} option={option} key={option.value} />
        ))}
      </FormGroup>
      {block.hint && <FormHelperText>{block.hint.text}</FormHelperText>}
    </FormControl>
  );
}

function CheckboxOption({ block, option }) {
  const { register } = useFormContext();

  const defaultChecked = !!block.element.initial_options?.find(
    (initialOption) => {
      return isEqual(option, initialOption);
    }
  );

  const { ref: checkboxRef, ...checkboxProps } = register(
    block.element.action_id
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          value={option.value}
          defaultChecked={defaultChecked}
          inputRef={checkboxRef}
          {...checkboxProps}
        />
      }
      label={option.text.text}
    />
  );
}
