import { Box } from "@material-ui/core";
import Head from "next/head";
import { Fragment } from "react";

import AppHeader from "../components/AppHeader";
import FullScreenLoader from "../components/FullScreenLoader";

import SiteLayout from "./Site";

import AcceptTermsForm from "@cosy/components/AcceptTermsForm";
import FullStory from "@cosy/components/FullStory";
import { AlgoliaIndexProvider } from "@cosy/hooks/use_algolia_index";
import { BlocksModalProvider } from "@cosy/hooks/use_blocks_modal";
import { HitsFocusModelProvider } from "@cosy/hooks/use_hits_focus_model";
import useLastVisitedWorkspace from "@cosy/hooks/use_last_visited_workspace";
import useMe from "@cosy/hooks/use_me";
import { SearchSetsProvider } from "@cosy/hooks/use_search_sets";
import { SearchStateProvider } from "@cosy/hooks/use_search_state";
import { SnackbarProvider } from "@cosy/hooks/use_snackbar";
import useWorkspace from "@cosy/hooks/use_workspace";
import useWorkspaceTheme from "@cosy/hooks/use_workspace_theme";
import NotFoundPage from "@cosy/pages/404.page";

export default function AppLayout({ children }) {
  const { me, mutate: mutateMe } = useMe();
  const { workspace, error } = useWorkspace();

  useLastVisitedWorkspace();
  useWorkspaceTheme(workspace);

  if (!workspace) {
    if (error) {
      return (
        <SiteLayout>
          <NotFoundPage />
        </SiteLayout>
      );
    }

    return <FullScreenLoader />;
  }

  return (
    <Fragment>
      {me?.termsOfUse.accepted === false && (
        <AcceptTermsForm onSuccess={() => mutateMe()} isDialog />
      )}
      <SnackbarProvider>
        <BlocksModalProvider>
          <AlgoliaIndexProvider>
            <SearchStateProvider>
              <SearchSetsProvider>
                <HitsFocusModelProvider>
                  <Head>
                    <title>{workspace.name}</title>
                  </Head>
                  <AppHeader workspace={workspace} />
                  <Box mt={14} ml={14} maxWidth={1200}>
                    <main>{children}</main>
                  </Box>
                </HitsFocusModelProvider>
              </SearchSetsProvider>
            </SearchStateProvider>
          </AlgoliaIndexProvider>
        </BlocksModalProvider>
      </SnackbarProvider>
      <FullStory />
    </Fragment>
  );
}

export const getLayout = (page) => <AppLayout>{page}</AppLayout>;
