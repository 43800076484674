import { MenuItem, TextField, ListSubheader } from "@material-ui/core";
import { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";

import logger from "@cosy/lib/logger";

export default function BlockInputStaticSelect({ block, handleInteraction }) {
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const { element, label, optional } = block;

  useEffect(() => {
    const { action_id, initial_option } = element;
    if (initial_option) {
      setValue(action_id, initial_option.value);
    }
  }, [element, setValue]);

  if (!element.options) {
    logger.error("Can not render BlockInputStaticSelect without options");
    return null;
  }

  const error = errors[element?.action_id];

  return (
    <Controller
      render={({ field: { onChange, ...fieldProps } }) => (
        <TextField
          disabled={!element.options}
          type="text"
          label={label?.text}
          placeholder={element.placeholder?.text}
          select
          InputProps={{
            onChange: (e) => {
              const selectedOption = e.target.value;
              onChange(selectedOption);
              handleInteraction({ ...element, value: selectedOption });
            },
          }}
          required={!optional}
          size="medium"
          error={!!error}
          helperText={error ? error.message : ""}
          {...fieldProps}
        >
          {optional && <MenuItem value="">-</MenuItem>}
          {element.option_groups?.length > 0
            ? element.option_groups.map((group) => [
                <ListSubheader key="group.label.text">
                  {group.label.text}
                </ListSubheader>,
                group.options?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text.text}
                  </MenuItem>
                )),
              ])
            : element.options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text.text}
                </MenuItem>
              ))}
        </TextField>
      )}
      name={element?.action_id}
      control={control}
      defaultValue=""
      rules={{
        required: !optional ? `${label.text} is required.` : false,
      }}
    />
  );
}
