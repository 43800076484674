import { useState, useEffect } from "react";
import { useAsync } from "react-use";

import useAlgoliaIndex from "@cosy/hooks/use_algolia_index";
import useSearchState from "@cosy/hooks/use_search_state";
import {
  INITIAL_SEARCH_PARAMS,
  INITIAL_SEARCH_RESULTS,
} from "@cosy/lib/constants/algolia";
import logger from "@cosy/lib/logger";

const HITS_AMOUNT_THRESHOLD = 2;

/**
 * Hook for components to get Algolia fallback results.
 *
 * @param {object} results - The SearchSet for general Algolia results.
 * @returns {object}
 */
export default function useAlgoliaFallback(results) {
  const index = useAlgoliaIndex();
  const { value, loading } = useAsync(async () => {
    if (!index) {
      logger.debug("No search index present, not searching.");
      return;
    }

    logger.debug("Running fallback search");
    return index.search("", {
      ...INITIAL_SEARCH_PARAMS,
      facetFilters: ["isFallback:true"],
    });
  }, [index]);

  const hits = _useUIHits(value?.hits || INITIAL_SEARCH_RESULTS.hits, results);

  return {
    hits,
    totalPages: value?.nbPages || INITIAL_SEARCH_RESULTS.nbPages,
    isLoading: loading,
  };
}

/**
 * @private
 */
function _useUIHits(rawHits, results) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { query } = useSearchState();

  useEffect(() => {
    setIsExpanded(false);
  }, [query]);

  // De-duplicate fallback actions which are also search hits
  const resultsObjectIDs = results?.hits.map((hit) => hit.objectID) ?? [];
  const dedupedHits = rawHits.filter(
    (hit) => !resultsObjectIDs.includes(hit.objectID)
  );

  // Slice hits if necessary for UI reasons
  const shouldSlice =
    dedupedHits.length > HITS_AMOUNT_THRESHOLD + 1 && !isExpanded;
  return shouldSlice
    ? dedupedHits.slice(0, HITS_AMOUNT_THRESHOLD).concat([
        {
          objectID: "fallback-show-more",
          title: "Show more",
          onClick: () => setIsExpanded(true),
        },
      ])
    : dedupedHits;
}
