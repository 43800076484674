import Grid from "@material-ui/core/Grid";

import Markdown from "@cosy/components/Markdown";

export default function BlockFields({ fields }) {
  return (
    <Grid container spacing={2}>
      {fields.map((field, i) => {
        return (
          <Grid xs={12} sm={6} key={i} item>
            <Markdown text={field.text} />
          </Grid>
        );
      })}
    </Grid>
  );
}
