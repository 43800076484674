import { createContext, useContext } from "react";

import useAlgoliaAnswers from "@cosy/hooks/use_algolia_answers";
import useAlgoliaFallback from "@cosy/hooks/use_algolia_fallback";
import useAlgoliaResults from "@cosy/hooks/use_algolia_results";
import usePeopleResults from "@cosy/hooks/use_people_results";
// import useNavResults from "@cosy/hooks/use_nav_results";

const SearchSetsContext = createContext();

/**
 * SearchSetsProvider value.
 *
 * @typedef SearchSetsProvider
 * @property {object} sets
 * @property {SearchSet} sets.results
 * @property {SearchSet} sets.people
 * @property {SearchSet} sets.fallback
 * @property {boolean} isLoading - If any of the sets is still loading.
 * @property {boolean} hasResults - If it was algolia or people results.
 */

/**
 * SearchSet definition.
 *
 * @typedef SearchSet
 * @type {object}
 * @property {Array} hits
 * @property {boolean} [isHorizontal] - Whether or not this set is displayed
 * horizontally. Controls how we handle keyboard navigation.
 * @property {boolean} [isLoading]
 * @property {number} [totalPages]
 * @property {Function} [updateHit]
 */

/**
 * React Provider for all sources of content that should be searchable.
 *
 * @param {object} props
 * @param {*} props.children
 * @returns {*}
 */
export function SearchSetsProvider({ children }) {
  const answers = useAlgoliaAnswers();
  const results = useAlgoliaResults(answers);
  const fallback = useAlgoliaFallback(results);
  const people = usePeopleResults();
  // const nav = useNavResults();

  return (
    <SearchSetsContext.Provider
      value={{
        sets: {
          answers,
          results,
          people,
          fallback,
          // nav
        },
        isLoading:
          results.isLoading ||
          people.isLoading ||
          fallback.isLoading ||
          answers.isLoading,
        hasResults:
          results.hits.length > 0 ||
          people.hits.length > 0 ||
          answers.hits.length > 0,
      }}
    >
      {children}
    </SearchSetsContext.Provider>
  );
}

const useSearchSets = () => useContext(SearchSetsContext);

export default useSearchSets;
