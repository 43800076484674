/**
 * See use_blocks_modal.test.js for tests
 */
import { TextField } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

export default function BlockInputPlainText({ block, autoFocus }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[block.element?.action_id];

  const { ref: blockInputRef, ...blockInputProps } = register(
    block.element?.action_id,
    { required: !block.optional ? `${block.label.text} is required.` : false }
  );

  return (
    <TextField
      type="text"
      size="medium"
      autoFocus={autoFocus}
      defaultValue={block.element?.initial_value}
      multiline={block.element?.multiline}
      rows={block.element?.multiline ? 3 : 1}
      label={block.label?.text}
      placeholder={block.element?.placeholder?.text}
      required={!block.optional}
      error={!!error}
      helperText={error ? error.message : ""}
      inputRef={blockInputRef}
      {...blockInputProps}
    />
  );
}
