import cloudinary from "cloudinary-core";

const client = cloudinary.Cloudinary.new({ cloud_name: "cosy-af" });

/**
 * Formats a Cloudinary image URL based on a Cloudinary object.
 *
 * @param {object} cloudinaryData - Object of Cloudinary data.
 * @param {object} cloudinaryData.public_id - Public ID used to generate the
 * URL.
 * @param {Array<string>} transformation - Transformations (or presets) to
 * apply.
 * @returns {string} Image URL.
 */
export function getCloudinaryURL(cloudinaryData, transformation = ["Card"]) {
  return (
    cloudinaryData?.public_id &&
    client.url(cloudinaryData?.public_id, {
      transformation,
      secure: true,
    })
  );
}

export default client;
