import { Box, InputAdornment, TextField } from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import { darken, rgba } from "polished";
import { useRef } from "react";

import SearchInputHitText from "./SearchInputHitText";

import useHitsFocusModel from "@cosy/hooks/use_hits_focus_model";
import useHotKeys from "@cosy/hooks/use_hotkeys";
import useKeyboardNav from "@cosy/hooks/use_keyboard_nav";
import useSearchState from "@cosy/hooks/use_search_state";

const useInputStyles = makeStyles((theme) => ({
  root: {
    transition: "all 80ms ease-in",
    "&:hover:not($focused) $notchedOutline": {
      borderColor: "transparent",
      boxShadow: theme.shadows[2],
    },
    "&:hover:not($focused)": {
      background: rgba(theme.palette.background.paper, 0.8),
    },
    flex: 1,
    background: rgba(darken(0.03, theme.palette.background.default), 0.8),
    borderRadius: theme.spacing(1),
    backdropFilter: "saturate(180%) blur(24px)",
  },
  notchedOutline: {
    borderColor: "transparent",
  },
  focused: {
    boxShadow: theme.shadows[4],
    background: rgba(theme.palette.background.paper, 0.8),
    "& $notchedOutline": {
      borderColor: "transparent !important",
    },
  },
}));

const useContainerStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
}));

export default function PrimarySearchInput({ placeholder = "Search" }) {
  const { query, setQuery } = useSearchState();
  const { focusedHit } = useHitsFocusModel();
  const { onFocusChange } = useKeyboardNav();
  const inputClasses = useInputStyles();
  const containerClasses = useContainerStyles();
  const searchInputRef = useRef();

  function onFocus(e) {
    if (query.length > 0) {
      e.target.select();
    }

    onFocusChange(true);
  }

  useHotKeys(
    "/",
    (e) => {
      e.preventDefault();
      searchInputRef.current.focus();
    },
    "searchInputBlurred"
  );

  return (
    <div className={containerClasses.root}>
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        zIndex="2"
        style={{ pointerEvents: "none" }}
      >
        <SearchInputHitText hit={focusedHit} query={query} />
      </Box>

      <TextField
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onFocus={onFocus}
        onBlur={() => onFocusChange(false)}
        autoComplete="off"
        placeholder={placeholder}
        size="medium"
        inputRef={searchInputRef}
        InputProps={{
          classes: inputClasses,
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        inputProps={{
          autoFocus: true,
          "aria-label": "Search",
          tabIndex: 1,
          // maxLength from: https://www.algolia.com/doc/api-reference/api-parameters/query/
          maxLength: 512,
          "data-testid": "primarySearch",
        }}
      />
    </div>
  );
}
