import { useEffect } from "react";
import ReactFullStory, { identify } from "react-fullstory";

import useMe from "@cosy/hooks/use_me";
import { isExcludedFromAnalytics } from "@cosy/utils/analytics";

export default function FullStory() {
  const { me } = useMe();

  useEffect(
    function identifyMe() {
      if (process.env.NEXT_PUBLIC_FULLSTORY_ORG && me) {
        identify(me._id, {
          displayName: me.displayName,
          email: me.email,
        });
      }
    },
    [me]
  );

  if (
    !process.env.NEXT_PUBLIC_FULLSTORY_ORG ||
    isExcludedFromAnalytics(me?.email)
  ) {
    return null;
  }

  return <ReactFullStory org={process.env.NEXT_PUBLIC_FULLSTORY_ORG} />;
}
