import lodashTruncate from "lodash/truncate";

/**
 * Truncates a string if neccesary.
 *
 * @param {string } text
 * @param {number} limit
 * @returns {string}
 */
export default function truncate(text, limit) {
  if (!limit) {
    return text;
  }

  return lodashTruncate(text, { omission: "…", length: limit, separator: " " });
}
