import {
  Card,
  CardContent,
  CardActions,
  FormControl,
  Box,
  TextField,
  Button,
  Typography,
  Checkbox,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { patch } from "../utils/api";

import InternalLink from "@cosy/components/InternalLink";
import useMe from "@cosy/hooks/use_me";

const useStyles = makeStyles((theme) => ({
  extraGutters: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export default function AcceptTermsForm({ isDialog = false, onSuccess }) {
  const { me } = useMe();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit, getValues, control } = useForm();

  async function submitForm() {
    setIsSubmitting(true);
    await patch("/me", getValues());
    if (typeof onSuccess === "function") {
      onSuccess();
    }
    setIsSubmitting(false);
  }

  const { ref: nameRef, ...nameProps } = register("name");

  return (
    <Wrapper isDialog={isDialog}>
      <form onSubmit={handleSubmit(submitForm)}>
        <Title isDialog={isDialog}>Get started</Title>
        <Content isDialog={isDialog}>
          <Box mb={2}>
            <FormControl fullWidth>
              <TextField
                id="username"
                label="What’s your name?"
                defaultValue={me.name}
                autoFocus
                inputRef={nameRef}
                {...nameProps}
              />
            </FormControl>
          </Box>
          <Box>
            <AcceptTermsField register={register} />
          </Box>
        </Content>
        <Actions isDialog={isDialog}>
          <AcceptTermsButton
            control={control}
            onSubmit={handleSubmit(submitForm)}
            isSubmitting={isSubmitting}
          />
        </Actions>
      </form>
    </Wrapper>
  );
}

function Wrapper({ isDialog, children }) {
  return isDialog ? (
    <DialogWrapper>{children}</DialogWrapper>
  ) : (
    <Card style={{ width: 480, maxWidth: "100%" }}>{children}</Card>
  );
}

function Title({ isDialog, children }) {
  const classes = useStyles();

  return isDialog ? (
    <DialogTitle id="responsive-dialog-title">{children}</DialogTitle>
  ) : (
    <CardContent className={classes.extraGutters}>
      <Box mt={2}>
        <Typography variant="h2" align="center">
          {children}
        </Typography>
      </Box>
    </CardContent>
  );
}

function Content({ isDialog, children }) {
  const classes = useStyles();

  return isDialog ? (
    <DialogContent>{children}</DialogContent>
  ) : (
    <CardContent className={classes.extraGutters}>{children}</CardContent>
  );
}

function Actions({ isDialog, children }) {
  const classes = useStyles();

  return isDialog ? (
    <DialogActions>{children}</DialogActions>
  ) : (
    <Box mb={2}>
      <CardActions
        style={{ justifyContent: "flex-end" }}
        className={classes.extraGutters}
      >
        {children}
      </CardActions>
    </Box>
  );
}

function DialogWrapper({ children }) {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      fullScreen={isFullScreen}
      open
      fullWidth
      disableAutoFocus
      aria-labelledby="responsive-dialog-title"
    >
      {children}
    </Dialog>
  );
}

function AcceptTermsField({ register }) {
  const { ref: termsRef, ...termsProps } = register("termsOfUse", {
    required: true,
  });
  return (
    <Box display="flex" alignItems="center">
      <Checkbox
        id="accept-terms"
        required
        inputRef={termsRef}
        {...termsProps}
      />
      <InputLabel htmlFor="accept-terms">
        I accept the{" "}
        <InternalLink href="/privacy-policy">privacy policy</InternalLink>
        {" and "}
        <InternalLink href="/terms-of-use">terms of use</InternalLink>.
      </InputLabel>
    </Box>
  );
}

function AcceptTermsButton({ control, onSubmit, isSubmitting }) {
  const termsOfUse = useWatch({
    control,
    name: "termsOfUse",
    defaultValue: false,
  });

  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      onClick={onSubmit}
      disabled={isSubmitting || !termsOfUse}
    >
      Continue
    </Button>
  );
}
