import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/styles";
import { forwardRef } from "react";

export default forwardRef(function DangerColor(props, ref) {
  const theme = useTheme();
  const mq = useMediaQuery("(prefers-color-scheme: dark)", {});

  const { style } = props;
  delete props.style;

  const color = mq ? "light" : "dark";
  return (
    <div
      ref={ref}
      style={{ color: theme?.palette.error[color], ...style }}
      data-testid="danger-color"
    >
      {props.children}
    </div>
  );
});
