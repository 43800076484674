import { CardMedia } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { getCloudinaryURL } from "@cosy/utils/cloudinary";

export default function BlockImage({ block }) {
  const theme = useTheme();
  const cloudinaryURL = getCloudinaryURL(block._cloudinary_data);
  const image = cloudinaryURL || block.image_url;

  return (
    <CardMedia
      style={{
        marginLeft: -theme.spacing(4),
        marginRight: -theme.spacing(4),
        height: 200,
        marginBottom: theme.spacing(1),
      }}
      image={image}
      title={block.alt_text}
    />
  );
}
