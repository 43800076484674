import DOMPurify from "dompurify";
import marked from "marked";

import { isYesterday, isToday, isTomorrow } from "./datetime";

import logger from "@cosy/lib/logger";

const _SLACK_FORMAT_RE = /<(.+?)>/g;
const _UNSUPPORTED_SLACK_COMMAND_RE = /^(#C|@U|@W|!subteam)/;
const _SLACK_TOKEN_STRING_RE = /{(.+?)}/g;
const _LOCALE = "en-US";

marked.setOptions({
  gfm: true,
  breaks: true,
  smartypants: true,
});

/**
 * Formats markdown as HTML, purifies, and returns wrapped in an object for
 * React. Also respects Slack's custom syntax for dates.
 *
 * Some differences compared to Slack’s parser:
 * - Slack adds day suffixes (ie. 1st or 18th), whereas ours does not.
 * - Slack's date_num format is, eg. 2014-02-18, whereas our is 02/18/2014.
 *
 * @see https://api.slack.com/reference/surfaces/formatting#date-formatting
 * @param {string} text - Text in Markdown syntax.
 * @param {boolean} inline - Whether to use the inline Markdown parser.
 * @returns {object} - Object for use in React's "dangerouslySetInnerHTML".
 */
export default function formatMarkdown(text, inline = false) {
  text = _replaceSlackCommands(text);
  return {
    __html: DOMPurify.sanitize(
      inline ? marked.parseInline(text) : marked(text)
    ),
  };
}

/**
 * @private
 */
function _replaceSlackCommands(text) {
  return text.replace(_SLACK_FORMAT_RE, _formatSlackCommand);
}

/**
 * Takes a Slack-formatted command string (eg. <!date^1392734382^{date_num}|2014-02-18>)
 * and converts it to Markdown.
 *
 * @private
 */
function _formatSlackCommand(match, matchContent) {
  const unsupportedCommand = matchContent.match(_UNSUPPORTED_SLACK_COMMAND_RE);

  if (unsupportedCommand) {
    logger.warn(
      "Unsupported Slack command “%s” in: %s",
      unsupportedCommand[0],
      match
    );
    return match;
  }

  // Currently we ignore "fallback_text" as we always assume we can parse the
  // date
  const [mrkdText] = matchContent.split("|");
  const [command, timestamp, tokenString, optionalLink] = mrkdText.split("^");

  if (command !== "!date") {
    // Return non-date tags as they are
    return match;
  }

  return _formatSlackDate(timestamp, tokenString, optionalLink);
}

/**
 * @private
 */
function _formatSlackDate(timestamp, tokenString, optionalLink) {
  const dateTime = new Date(timestamp * 1000);

  const updatedTokenString = tokenString.replace(
    _SLACK_TOKEN_STRING_RE,
    (tokenMatch, format) => {
      if (Object.hasOwnProperty.call(DATE_TOKEN_FORMATTERS, format)) {
        return DATE_TOKEN_FORMATTERS[format](dateTime);
      }

      return "";
    }
  );

  return optionalLink
    ? `[${updatedTokenString}](${optionalLink})`
    : updatedTokenString;
}

export const DATE_TOKEN_FORMATTERS = {
  date_num: (dateTime) => dateTime.toLocaleDateString(),
  date: (dateTime) =>
    dateTime.toLocaleDateString(_LOCALE, {
      year: "numeric",
      month: "long",
      day: "2-digit",
    }),
  date_short: (dateTime) =>
    dateTime.toLocaleDateString(_LOCALE, {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }),
  date_long: (dateTime) =>
    dateTime.toLocaleDateString(_LOCALE, {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }),
  date_pretty: (dateTime) =>
    _relativeDateOrFunction(dateTime, DATE_TOKEN_FORMATTERS.date),
  date_short_pretty: (dateTime) =>
    _relativeDateOrFunction(dateTime, DATE_TOKEN_FORMATTERS.date_short),
  date_long_pretty: (dateTime) =>
    _relativeDateOrFunction(dateTime, DATE_TOKEN_FORMATTERS.date_long),
  time: (dateTime) =>
    dateTime.toLocaleTimeString(_LOCALE, {
      hour: "2-digit",
      minute: "2-digit",
    }),
  time_secs: (dateTime) => dateTime.toLocaleTimeString(),
};

/**
 * @private
 */
function _relativeDateOrFunction(dateTime, fn) {
  if (isYesterday(dateTime)) {
    return "yesterday";
  } else if (isToday(dateTime)) {
    return "today";
  } else if (isTomorrow(dateTime)) {
    return "tomorrow";
  } else {
    return fn(dateTime);
  }
}
