import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { createContext, useContext, useState, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";

import useWorkspace from "./use_workspace";

import Blocks from "@cosy/components/Blocks";
import useHotKeys from "@cosy/hooks/use_hotkeys";
import useSnackbar from "@cosy/hooks/use_snackbar";
import logger from "@cosy/lib/logger";
import { post } from "@cosy/utils/api";

const BlocksModalContext = createContext({});

/**
 * React Provider which includes a Modal that renders blocks, and provides
 * a method for opening it.
 *
 * @param {object} props
 * @param {*} props.children
 * @returns {*}
 */
export function BlocksModalProvider({ children }) {
  const [modalData, setModalData] = useState();
  const [optionsLoadURL, setOptionsLoadURL] = useState();
  const [interactionURL, setInteractionURL] = useState();
  const [submitURL, setSubmitURL] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const submittedCallback = useRef();

  const { workspace } = useWorkspace();
  const { openSnackbar } = useSnackbar();

  // TODO: Add validation
  const methods = useForm();
  const { handleSubmit, getValues, reset } = methods;

  // TODO: Use onExit or similar
  const handleClose = () => {
    setModalData(null);
    setOptionsLoadURL(null);
    setSubmitURL(null);
    setInteractionURL(null);
    reset();
  };

  const openModal = (
    modalData,
    submitUrl,
    { optionsLoadURL, onSubmittedCallback, interactionURL }
  ) => {
    setModalData(modalData);
    setSubmitURL(submitUrl);
    if (optionsLoadURL) {
      setOptionsLoadURL(optionsLoadURL);
    }
    if (interactionURL) {
      setInteractionURL(interactionURL);
    }
    if (onSubmittedCallback) {
      submittedCallback.current = onSubmittedCallback;
    }
  };

  const handleInteraction = async (action) => {
    if (!interactionURL) {
      return;
    }
    const payload = {
      type: "block_actions",
      state: getValues(),
      actions: [action],
    };

    logger.debug("Post interaction payload");
    const { data } = await post(interactionURL, payload);

    if (data?.response_action === "update" && data.view) {
      setModalData(data.view);
    }
  };

  const submitForm = async () => {
    setIsSubmitting(true);

    const view = Object.assign({}, modalData, { state: getValues() });
    logger.debug("Submitting view with state: %o", view);
    const { data, error } = await post(submitURL, {
      view,
    });
    setIsSubmitting(false);
    if (error) {
      openSnackbar("There was an error performing the action.");
    } else {
      openSnackbar(data?.text);
    }
    if (submittedCallback.current) {
      submittedCallback.current();
    }

    handleClose();
  };

  const isModalOpen = Boolean(modalData);
  isModalOpen && logger.debug("Rendering modal: %o", modalData);

  return (
    <BlocksModalContext.Provider value={{ openModal }}>
      {children}
      {isModalOpen && (
        <Dialog
          fullWidth
          open={isModalOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <KeyboardHandlers onSubmit={handleSubmit(submitForm)} />
          <FormProvider {...methods}>
            <DialogTitle id="form-dialog-title">
              {/*<HitIcon size={24} hit={hit} />*/}
              {modalData.title.text}
            </DialogTitle>
            <DialogContent>
              <Blocks
                blocks={modalData.blocks}
                optionsLoadURL={optionsLoadURL}
                handleInteraction={handleInteraction}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} disabled={isSubmitting}>
                {modalData.close?.text || "Cancel"}
              </Button>
              <Button
                onClick={handleSubmit(submitForm)}
                color="primary"
                variant="contained"
                disabled={!workspace || isSubmitting}
              >
                {modalData.submit?.text || "Submit"}
              </Button>
            </DialogActions>
          </FormProvider>
        </Dialog>
      )}
    </BlocksModalContext.Provider>
  );
}

/**
 * Adds the keyboard shortcuts for the modal.
 * We do it here so they’re automatically unbound when the modal is removed.
 *
 * @param {object} props
 * @param {Function} props.onSubmit
 * @returns {null}
 */
function KeyboardHandlers({ onSubmit }) {
  useHotKeys("ctrl+return, command+return", (e) => {
    e.preventDefault();
    onSubmit();
  });

  return null;
}

/**
 * @typedef {object} BlocksModalContextValues
 * @property {Function} openModal - A function for opening a new modal, which
 * accepts an Algolia result.
 */

/**
 * React Hook for accessing BlocksModalContext.
 *
 * @returns {BlocksModalContextValues}
 */
export default function useBlocksModal() {
  return useContext(BlocksModalContext);
}
