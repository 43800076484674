import isEqual from "lodash/isEqual";
import Router from "next/router";
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  createContext,
} from "react";

import { INITIAL_SEARCH_PARAMS } from "@cosy/lib/constants/algolia";

const SearchStateContext = createContext();

/**
 * Hook for components to get search state from parent Provider.
 *
 * @returns {*}
 */
export default function useSearchState() {
  return useContext(SearchStateContext);
}

/**
 * Provider component that makes search state available
 * to any children that calls useSearchState().
 *
 * @param {object} props
 * @param {*} props.children
 * @returns {*}
 */
export function SearchStateProvider({ children }) {
  const searchState = _useProvideSearchState();
  return (
    <SearchStateContext.Provider value={searchState}>
      {children}
    </SearchStateContext.Provider>
  );
}

/**
 * Provider hook that handles query and browse mode state,
 * and updating url query params when state changes.
 *
 * @returns {object}
 */
function _useProvideSearchState() {
  const {
    pathname,
    query: { q, browse },
  } = Router;

  const [query, setQuery] = useState(q || "");
  const [isBrowseMode, setIsBrowseMode] = useState(browse || false);
  const [searchParams, setSearchParams] = useState(INITIAL_SEARCH_PARAMS);
  const isInitialRender = useRef(true);
  const queryRef = useRef(query);
  const searchParamsRef = useRef(searchParams);

  if (!isEqual(searchParams, searchParamsRef.current)) {
    searchParamsRef.current = searchParams;
  }

  const setPage = useCallback(
    (page) => {
      setSearchParams({ ...searchParams, page });
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    const isOnSearchPage = Router.pathname === "/[slug]";
    const isExitingSearchPage = !isOnSearchPage && !query && !isBrowseMode;

    if (isInitialRender.current || isExitingSearchPage || !Router.query.slug) {
      return;
    }

    const queryParams = {};
    if (query) {
      queryParams.q = query;
    }
    if (isBrowseMode) {
      queryParams.browse = 1;
    }

    // Don't update browser history if still on the search page
    const method = isOnSearchPage ? "replace" : "push";

    Router[method](
      {
        pathname: `/${Router.query.slug}`,
        query: queryParams,
      },
      undefined,
      {
        shallow: true,
      }
    );
  }, [query, isBrowseMode]);

  useEffect(() => {
    if (pathname !== "/[slug]") {
      setQuery("");
      setIsBrowseMode(false);
    }
  }, [pathname]);

  useEffect(() => {
    isInitialRender.current = false;
  }, []);

  useEffect(() => {
    if (query !== queryRef.current) {
      setPage(0);
      queryRef.current = query;
    }
  }, [query, setPage]);

  return {
    query,
    setQuery,
    isBrowseMode,
    isQueryReset: query === "" && queryRef.current !== "",
    setIsBrowseMode,
    searchParams: searchParamsRef.current,
    setSearchParams,
    page: searchParams.page,
    setPage,
  };
}
