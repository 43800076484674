export const FEATURES = {
  MEMBERS: {
    label: "Team members",
    doc: "Add members and a team for shared search results and widgets.",
    id: "MEMBERS",
  },
  UNLIMITED_APPS: {
    label: "Unlimited apps",
    doc: "Connect more than 2 search apps.",
    id: "UNLIMITED_APPS",
  },
};

export const PLANS = {
  PERSONAL: {
    id: "PERSONAL",
    label: "Personal",
    description: "Free cloud dashboard with up to two integrations.",
    features: [],
  },
  PRO: {
    id: "PRO",
    label: "Pro",
    description:
      "Add unlimited apps and search sources. Free during the early release.",
    features: [FEATURES.UNLIMITED_APPS],
  },
  TEAM: {
    id: "TEAM",
    label: "Team",
    description: "Shared search sources and team directory.",
    features: [FEATURES.MEMBERS, FEATURES.UNLIMITED_APPS],
  },
};

export const STATUSES = {
  TRIAL: { id: "TRIAL" },
  ACTIVE: { id: "ACTIVE" },
  OVERDUE: { id: "OVERDUE" },
  CANCELED: { id: "CANCELED" },
  TOMBSTONED: { id: "TOMBSTONED" },
};

export const SLUG_DENYLIST = [
  // App pages
  "404",
  "api",
  "index",
  "login",
  "onboarding",

  // Potential future marketing site pages
  "about",
  "account",
  "blog",
  "case-studies",
  "company",
  "contact",
  "customer-stories",
  "enterprise",
  "features",
  "help",
  "learn",
  "legal",
  "oauth",
  "press",
  "pricing",
  "privacy",
  "privacy-policy",
  "product",
  "profile",
  "security",
  "settings",
  "signup",
  "slack",
  "solutions",
  "status",
  "support",
  "team",
  "terms",
  "terms-of-service",
  "terms-of-use",
  "tos",
  "use-cases",
  "webinars",
];
