import { useCallback } from "react";
import { event } from "react-fullstory";

import useBlocksModal from "@cosy/hooks/use_blocks_modal";
import usePostInteration from "@cosy/hooks/use_post_interaction";
import useSearchState from "@cosy/hooks/use_search_state";
import useSlug from "@cosy/hooks/use_slug";
import { events } from "@cosy/lib/constants/analytics";
import logger from "@cosy/lib/logger";
import { post } from "@cosy/utils/api";
import { replaceQuery } from "@cosy/utils/replace_url_query";

/**
 * React Hook for taking action on a result hit.
 *
 * @param {boolean} isFallback - Context where the hit is being used.
 * @returns {Function}
 */
export default function usePerformAction(isFallback = false) {
  const { query, setQuery } = useSearchState();
  const { openModal } = useBlocksModal();
  const postInteraction = usePostInteration();
  const slug = useSlug();

  const sendActivity = _useSendActivity(isFallback);

  const performAction = useCallback(
    (result, isKeyboardNavigation = false) => {
      sendActivity(result);
      event(events.ui.RESULT_CLICK, { result });

      // Special treament for custom hits that has onClick
      if (result.onClick) {
        result.onClick();
        return;
      }

      // Special treament for URL since it can be handled by HTML link
      if (result.type === "url") {
        // Force update for when is triggered by Enter key press
        if (isKeyboardNavigation) {
          window.location.href = result.url;
        }
        return;
      }

      const startsWithKeyword = query.indexOf(result.keyword) === 0;
      const isWebSearch = result.subType === "Web search";
      const isFallbackWebSearch = isWebSearch && isFallback;
      const searchSource = result.searchSource || result.visibility?.scopeID;

      if (result.subType === "Modal") {
        openModal(
          result.modal,
          `/workspaces/${slug}/sources/${searchSource}/view`,
          {
            optionsLoadURL: `/workspaces/${slug}/sources/${searchSource}/options`,
            interactionURL: `/workspaces/${slug}/sources/${searchSource}/interaction`,
          }
        );
      } else if (!startsWithKeyword && !isFallbackWebSearch) {
        setQuery(`${result.keyword} `);
      } else if (isWebSearch) {
        window.location.href = replaceQuery(
          result.webSearch.url,
          query,
          result.keyword
        );
      } else {
        const actionValue = _replaceKeyword(query, result.keyword);

        postInteraction(result, {
          value: actionValue,
          type: "result",
        });
      }
    },
    [
      query,
      setQuery,
      isFallback,
      openModal,
      slug,
      postInteraction,
      sendActivity,
    ]
  );

  return performAction;
}

/**
 * @private
 */
function _replaceKeyword(input, keyword) {
  return input.replace(new RegExp("^" + keyword + " *"), "");
}

/**
 * @private
 */
function _useSendActivity(isFallback) {
  const { query } = useSearchState();

  const sendActivity = useCallback(
    (result) => {
      // Don't record successful hit if we're using a fallback result
      if (isFallback) {
        return;
      }

      _sendActivity({
        objectID: result.objectID,
        query,
      });
    },
    [query, isFallback]
  );

  return sendActivity;
}

/**
 * @private
 */
function _sendActivity(body) {
  // TODO: Come up with better approach, maybe use location.protocol
  if (navigator.sendBeacon && !process.env.CHROME_EXTENSION) {
    const blob = new Blob([JSON.stringify(body)], {
      type: "application/json",
    });

    try {
      return navigator.sendBeacon("/api/activity", blob);
    } catch (e) {
      logger.error(e);
    }
  }

  post("/activity", body);
}
