import { utcToZonedTime } from "date-fns-tz";

import { timezones } from "@cosy/lib/constants/timezones";

const _LOCALE = "en-US";

/**
 * Check if date is today.
 *
 * @param {Date} dateTime
 * @returns {boolean}
 */
export function isTomorrow(dateTime) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return _compareDates(dateTime, tomorrow);
}

/**
 * Check if date is tomorrow.
 *
 * @param {Date} dateTime
 * @returns {boolean}
 */
export function isToday(dateTime) {
  const today = new Date();

  return _compareDates(dateTime, today);
}

/**
 * Check if date is yesterday.
 *
 * @param {Date} dateTime
 * @returns {boolean}
 */
export function isYesterday(dateTime) {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return _compareDates(dateTime, yesterday);
}

/**
 * Takes a Date and converts it to a friendly readable time string.
 *
 * @example 6pm
 * @example 7:15
 * @param {Date} date
 * @param {boolean} [includeAmPm=false]
 * @returns {string}
 */
export function formatReadableTime(date, includeAmPm = false) {
  const hours = date.getHours();
  const mins = date.getMinutes();

  let timeString = _get12HourTime(hours);

  if (mins !== 0) {
    timeString += `:${mins < 10 ? "0" + mins : mins}`;
  }

  if (includeAmPm) {
    timeString += hours < 12 ? "am" : "pm";
  }

  return timeString;
}

/**
 * Formats two values as readable time, in a range.
 *
 * @example 6 – 6:15am
 * @param {Date} start
 * @param {Date} end
 * @returns {*} JSX time range.
 */
export function formatReadableTimeRange(start, end) {
  return (
    <>
      {formatReadableTime(start)}&thinsp;–&thinsp;
      {formatReadableTime(end, true)}
    </>
  );
}

/**
 * Get localtime based on the timezone.
 *
 * @param {string} timeZone
 * @returns {Date}
 */
export function getLocalTime(timeZone) {
  if (!timeZone) {
    return;
  }
  const localTimeZone = timezones.find((tz) => tz.text === timeZone);

  if (!localTimeZone) {
    throw new Error("Can not deconstruct error");
  }

  const {
    offset,
    utc: [utcTimeZone],
  } = localTimeZone;

  const zonedDate = utcToZonedTime(new Date(), utcTimeZone);

  return {
    time: formatReadableTime(zonedDate, true),
    offset,
  };
}

/**
 * @private
 */
function _get12HourTime(hours) {
  return (hours + 24) % 12 || 12;
}

/**
 * @private
 */
function _compareDates(date1, date2) {
  return (
    date1.toLocaleDateString(_LOCALE) === date2.toLocaleDateString(_LOCALE)
  );
}
