import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

import formatMarkdown from "../utils/format_markdown";

const useStyles = makeStyles((theme) => ({
  root: {
    /* From https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
    maxWidth: "100%",

    /* These are technically the same, but use both */
    overflowWrap: "break-word",
    wordWrap: "break-word",
    wordBreak: "break-word",

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    hyphens: "auto",

    "& p": {
      margin: "0 0 .5em 0",
    },
    "& ul, & ol": {
      margin: "0 0 1em 0",
      padding: "0 0 0 1em",
    },

    "& h1": {
      margin: "1.2em 0 .3em",
      ...theme.typography.h2,
    },
    "& h2": {
      margin: "1.2em 0 .3em",
      ...theme.typography.h3,
    },
    "& h3": {
      margin: "1.2em 0 .3em",
      ...theme.typography.h4,
    },
    "& blockquote": {
      borderLeft: "4px solid rgba(155, 155, 155, 0.2)",
      margin: "0.5em 0",
      paddingLeft: "1em",
    },
    "& img": {
      maxWidth: "100%",
    },
    "& pre": {
      maxWidth: "100%",
      overflow: "auto",

      "& > code": {
        display: "block",
        padding: theme.spacing(1),
      },
    },
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",

      "&:hover": {
        textDecoration: "underline",
      },
    },
    "& code": {
      fontFamily: 'Menlo, "Lucida Console", Monaco, monospace',
      fontSize: ".9em",
      padding: "1px 3px",
      borderRadius: "3px",
      background: theme.palette.background.default,
    },

    "& hr": {
      margin: "8px 0",
      border: 0,
      borderBottom: theme.palette.divider,
    },
  },
}));

export default function Markdown({
  text,
  inline = false,
  className,
  ...otherProps
}) {
  const component =
    otherProps.component || (inline && !otherProps.paragraph) ? "span" : "div";

  const classes = useStyles();
  return (
    <Typography
      className={clsx(classes.root, className)}
      component={component}
      dangerouslySetInnerHTML={formatMarkdown(text, inline)}
      {...otherProps}
    ></Typography>
  );
}
