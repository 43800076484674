import { useAsync } from "react-use";

import useSearchState from "@cosy/hooks/use_search_state";
import useSlug from "@cosy/hooks/use_slug";
import logger from "@cosy/lib/logger";
import { get } from "@cosy/utils/api";

/**
 * Hook for components to get people results from the People API.
 *
 * @returns {*}
 */
export default function usePeopleResults() {
  const slug = useSlug();
  const { query } = useSearchState();

  const { value, loading } = useAsync(async () => {
    if (!query.length) {
      logger.debug("Serving blank default results");
      return { data: [] };
    }

    logger.debug("Running people search “%s”", query);
    return get(`/workspaces/${slug}/users?v=2&q=${query}`);
  }, [slug, query]);

  return {
    hits: value?.data?.users || [],
    isLoading: loading,
    isHorizontal: true,
  };
}
