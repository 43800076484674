import { Avatar } from "@material-ui/core";

export default function WorkspaceAvatar({ workspace, size = 44 }) {
  return (
    <Avatar
      style={{
        backgroundColor:
          !workspace.design?.logo?.secure_url && workspace.design?.color,
        width: size,
        height: size,
        fontSize: "1rem",
      }}
      alt={`${workspace.name} icon`}
      src={workspace.design?.logo?.secure_url}
      variant="rounded"
    >
      {workspace.name?.slice(0, 1)}
    </Avatar>
  );
}
