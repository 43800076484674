import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useRouter } from "next/router";

import InternalLink from "@cosy/components/InternalLink";
import PrimarySearchInput from "@cosy/components/PrimarySearchInput";
import UserMenu from "@cosy/components/UserMenu";
import WorkspaceAvatar from "@cosy/components/WorkspaceAvatar";
import useAlgoliaIndex from "@cosy/hooks/use_algolia_index";
import useMe from "@cosy/hooks/use_me";
import useSearchState from "@cosy/hooks/use_search_state";
import { FEATURES } from "@cosy/lib/constants/workspaces";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(3, 6, 3, 4),
    marginBottom: theme.spacing(4),
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
}));

export default function AppHeader({ workspace }) {
  const classes = useStyles();
  const { me } = useMe();
  const algoliaIndex = useAlgoliaIndex();
  const router = useRouter();
  const { setQuery } = useSearchState();

  const hasTeamFeatures = workspace.features.some(
    (f) => f.id === FEATURES.MEMBERS.id
  );

  return (
    <Box display="flex" alignItems="center" className={classes.header}>
      <Box flex="1">
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Button
              data-testid="home-icon"
              onClick={() => {
                setQuery("");
                router.push(`/${workspace.slug}`);
              }}
            >
              <WorkspaceAvatar workspace={workspace} />
            </Button>
          </Grid>
          {algoliaIndex && (
            <Grid item xs={12} sm={6}>
              <PrimarySearchInput placeholder={`Search ${workspace.name}`} />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          {hasTeamFeatures && (
            <NavLink
              href={`/${workspace.slug}/people`}
              path="/[slug]/people"
              title="People"
            >
              People
            </NavLink>
          )}
          <NavLink
            href={`/${workspace.slug}/settings`}
            path="/[slug]/settings"
            title="Settings"
          >
            Settings
          </NavLink>
          <Grid item zeroMinWidth>
            <UserMenu user={me} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

function NavLink({ href, onMouseEnter, path, pathIsIndex, children }) {
  const router = useRouter();
  const { pathname } = router;

  const isActive = pathIsIndex ? pathname === path : pathname.startsWith(path);

  return (
    <Grid item>
      <InternalLink
        href={href}
        linkComponent={Button}
        linkProps={{
          disableRipple: true,
          color: isActive ? "primary" : "textSecondary",
          onMouseEnter,
        }}
      >
        {children}
      </InternalLink>
    </Grid>
  );
}
