import { getCloudinaryURL } from "@cosy/utils/cloudinary";

export default function BlockElementImage({ element }) {
  const cloudinaryURL = getCloudinaryURL(element._cloudinary_data, [
    "Thumbnail",
  ]);
  const src = cloudinaryURL || element.image_url;

  return (
    <div style={{ width: 120 }}>
      <img
        alt={element.alt_text}
        style={{
          display: "block",
          margin: "0 auto",
          maxWidth: 120,
          maxHeight: 500,
        }}
        src={src}
      />
    </div>
  );
}
