import { useEffect } from "react";
import useSWR from "swr";

import useLastVisitedWorkspace from "@cosy/hooks/use_last_visited_workspace";
import useSlug from "@cosy/hooks/use_slug";

/**
 * @typedef WorkspaceValues
 * @property {?object} workspace - The Workspace object, or undefined if it
 * hasn't loaded yet.
 * @property {Function} mutate - A function to update the current Workspace
 * and/or revalidate it against the server.
 * @property {?Error} error - Any potential error from the fetch call.
 */

/**
 * Returns an SWR call for the current Workspace.
 *
 * @returns {WorkspaceValues}
 */
export default function useWorkspace() {
  const slug = useSlug();
  const { data: workspace, ...swrData } = useSWR(
    () => slug && "/api/workspaces/" + slug
  );

  const [lastVisitedWorkspace, setLastVisitedWorkspace] =
    useLastVisitedWorkspace();

  useEffect(() => {
    if (workspace?.slug && workspace.slug !== lastVisitedWorkspace) {
      setLastVisitedWorkspace(workspace.slug);
    }
  }, [workspace, lastVisitedWorkspace, setLastVisitedWorkspace]);

  return { ...swrData, workspace };
}
