import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect, useState, useMemo } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useAsync } from "react-use";

import useSnackbar from "@cosy/hooks/use_snackbar";
import logger from "@cosy/lib/logger";
import { get } from "@cosy/utils/api";

export default function BlockInputExternalSelect({
  block,
  optionsLoadURL,
  handleInteraction,
}) {
  const [query, setQuery] = useState("");

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { openSnackbar } = useSnackbar();

  const {
    value: optionsResponse,
    error: loadError,
    loading,
  } = useAsync(async () => {
    if (!optionsLoadURL) {
      return;
    }

    const { data, error } = await get(
      `${optionsLoadURL}?action=${block.element.action_id}&q=${query}`
    );

    if (error) {
      throw error;
    }

    return data;
  }, [optionsLoadURL, block.element.action_id, query]);

  const options = useMemo(() => {
    if (optionsResponse) {
      return optionsResponse.option_groups
        ? optionsResponse.option_groups
            .map(_formatBlockKitOptionGroupAsOptions)
            .flat()
        : optionsResponse.options;
    } else {
      return block.element.initial_option ? [block.element.initial_option] : [];
    }
  }, [block, optionsResponse]);

  const isGrouped = useMemo(
    () => !!optionsResponse?.option_groups,
    [optionsResponse]
  );

  // Set the default value if required and there are options
  useEffect(() => {
    if (
      options.length > 0 &&
      !block.optional &&
      !getValues(block.element.action_id)
    ) {
      setValue(block.element.action_id, options[0].value);
      handleInteraction({
        ...block.element,
        value: options[0].value,
      });
    }
  }, [options, block, getValues, setValue, handleInteraction]);

  if (!optionsLoadURL) {
    logger.error(
      "Can not render BlockInputExternalSelect without an optionsLoadURL"
    );
    return null;
  }

  if (loadError) {
    if (block.optional) {
      return null;
    }
    openSnackbar("Failed to load options");
  }

  const error = errors[block.element?.action_id];

  // Support typeahead
  // - Loads options initially and when query changes.
  return (
    <Controller
      defaultValue=""
      render={({ field: { value, onChange, ...fieldProps } }) => (
        <Autocomplete
          data-testid="external-select"
          value={value}
          onChange={(e, option) => {
            onChange(option);
            option && handleInteraction({ ...block.element, value: option });
          }}
          getOptionSelected={(option, value) => option === value}
          options={options.map((option) => option.value)}
          loading={loading}
          getOptionLabel={(value) =>
            options.find((item) => item.value === value)?.text?.text || ""
          }
          groupBy={
            isGrouped &&
            ((value) => options.find((item) => item.value === value)?.group)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={block.label?.text}
              variant="outlined"
              size="medium"
              error={!!error}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              value={query}
              helperText={error ? error.message : ""}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          {...fieldProps}
        />
      )}
      name={block.element?.action_id}
      control={control}
      rules={{
        required: !block.optional ? `${block.label.text} is required.` : false,
      }}
    />
  );
}

/*
 * @private
 */
function _formatBlockKitOptionGroupAsOptions(group) {
  return group.options.map((option) => ({
    group: group.label.text,
    ...option,
  }));
}
