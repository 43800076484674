import { Box, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import escapeRegExp from "lodash/escapeRegExp";

import SearchInputHitIcon from "./SearchInputHitIcon";

/**
 * This copies styles from Material UI in order to apply input styling to a
 * div. This is used to show the "autocomplete" text behind/in the
 * PrimarySearchInput.
 *
 * @see https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/InputBase/InputBase.js
 * @see https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/OutlinedInput/OutlinedInput.js
 */
const useStyles = makeStyles((theme) => ({
  fakeInputText: {
    ...theme.typography.body1,
    height: "1.1876em",
    padding: "18.5px 0",
    opacity: 0.4,
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    minWidth: 0,
    lineHeight: "1.1876em",
    boxSizing: "content-box",
  },
  smallTitleText: {
    ...theme.typography.caption,
    color: theme.palette.text.disabled,
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    flex: 1,
  },
}));

export default function SearchInputHitText({ hit, query }) {
  const classes = useStyles();

  if (!hit || !query) {
    return null;
  }

  const title = hit.title || hit.name;
  const replaced = title?.replace(
    new RegExp(`^${escapeRegExp(query)}`, "i"),
    ""
  );
  return (
    <Box
      display="flex"
      alignItems="center"
      paddingLeft="14px"
      lineHeight="1.1876em"
      height="100%"
      boxSizing="border-box"
    >
      <InputAdornment position="start">
        <Search style={{ opacity: 0 }} />
      </InputAdornment>
      <div className={classes.fakeInputText}>
        <span style={{ opacity: 0 }}>{query}</span>
        <span>{replaced !== title && replaced}</span>
      </div>
      {replaced === title ? (
        <div className={classes.smallTitleText}>&nbsp;— {replaced}</div>
      ) : (
        <Box flex={1}></Box>
      )}

      <InputAdornment position="end">
        <SearchInputHitIcon hit={hit} query={query} />
      </InputAdornment>
    </Box>
  );
}
