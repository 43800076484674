import { EMAIL_BLOCKLIST } from "@cosy/lib/constants/analytics";

/**
 * Checks to see if an email is excluded from analytics tracking (ie. Doesn't
 * boot FullStory or Intercom). Note that this will return true if the provided
 * email doesn't exist.
 *
 * @param {string} email
 * @returns {boolean}
 */
export function isExcludedFromAnalytics(email) {
  return (
    !email ||
    EMAIL_BLOCKLIST.some((blockedSuffix) => email.endsWith(blockedSuffix))
  );
}
