import { Snackbar } from "@material-ui/core";
import { createContext, useContext, useState } from "react";

import logger from "@cosy/lib/logger";

const SnackbarContext = createContext({});

export function SnackbarProvider({ children }) {
  const [message, setMessage] = useState(null);

  const openSnackbar = (message) => setMessage(message);
  const closeSnackbar = () => setMessage(null);

  message && logger.debug("Rendering snackbar: %o", message);

  return (
    <SnackbarContext.Provider value={{ openSnackbar }}>
      {children}
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{message}</span>}
      />
    </SnackbarContext.Provider>
  );
}

export default function useSnackbar() {
  return useContext(SnackbarContext);
}
