import { MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { forwardRef } from "react";

import DangerColor from "./DangerColor";

export default forwardRef(function InternalMenuItem(
  { isDestructive, ...props },
  ref
) {
  return isDestructive ? (
    <DangerColor ref={ref}>
      <Item {...props} iconColor="inherit" />
    </DangerColor>
  ) : (
    <Item {...props} />
  );
});

function Item({
  iconComponent,
  iconColor,
  iconProps,
  children,
  text,
  secondaryText,
  ...otherProps
}) {
  return (
    <MenuItem {...otherProps}>
      <ItemIcon
        iconComponent={iconComponent}
        iconColor={iconColor}
        {...iconProps}
      />
      <ListItemText primary={text || children} secondary={secondaryText} />
    </MenuItem>
  );
}

function ItemIcon({ iconComponent, iconColor = "secondary", ...iconProps }) {
  if (!iconComponent) {
    return null;
  }

  const IconComponent = iconComponent;

  return (
    <ListItemIcon>
      <IconComponent fontSize="small" color={iconColor} {...iconProps} />
    </ListItemIcon>
  );
}
