import hotkeys from "hotkeys-js";
import { useEffect, useRef } from "react";

// Always use hotkeys, even when a text input is focused
hotkeys.filter = () => true;

/**
 * React Hook for adding a handler for a specific key-combo via hotkeys.
 *
 * @param {string} keys
 * @param {Function} callback
 * @param {string} scope
 */
export default function useHotKeys(keys, callback, scope = "all") {
  // Create a ref that stores handler
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = callback;
  }, [callback]);

  useEffect(() => {
    hotkeys(keys, scope, (e) => savedHandler.current(e));
    return () => hotkeys.unbind(keys, scope);
  }, [keys, scope]);
}
