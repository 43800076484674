import { useEffect } from "react";

import useCustomTheme from "./use_custom_theme";

/**
 * React hook which overrides the current theme with any changes from the
 * current Workspace.
 *
 * @param {object} workspace - Current Workspace object.
 */
export default function useWorkspaceTheme(workspace) {
  const { setCustomTheme } = useCustomTheme();
  useEffect(() => {
    if (!workspace) {
      return;
    }
    setCustomTheme({
      palette: {
        primary: {
          main: workspace.design.color,
        },
      },
    });
  }, [workspace, setCustomTheme]);
}
