import { Box, Button } from "@material-ui/core";
import { Fragment, useState } from "react";

import BlockElementImage from "./BlockElementImage";
import BlockFields from "./BlockFields";
import Markdown from "./Markdown";

import truncate from "@cosy/lib/formatters/truncate";

const TEXT_CHARS_LIMIT = 660;

function DisplayText({ text = "" }) {
  const [limit, setLimit] = useState(
    text.length > TEXT_CHARS_LIMIT ? TEXT_CHARS_LIMIT : undefined
  );

  const visibleText = truncate(text, limit);

  return (
    <Fragment>
      <Markdown text={visibleText} />
      {limit && (
        <Box mt={2} mb={4}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => setLimit(undefined)}
          >
            Expand
          </Button>
        </Box>
      )}
    </Fragment>
  );
}

export default function BlockSection({ block }) {
  return (
    <div>
      {block.accessory?.type === "image" && (
        <Box align="center">
          <BlockElementImage element={block.accessory} />
        </Box>
      )}
      <Box display="flex" maxWidth="100%">
        <Box flex="1" maxWidth="100%" mr={2}>
          {block.text && <DisplayText text={block.text.text} />}
        </Box>
      </Box>
      {block.fields?.length > 0 && <BlockFields fields={block.fields} />}
    </div>
  );
}
