import { Typography } from "@material-ui/core";
import useSWR from "swr";

export function HitIconImg({ size, src }) {
  return (
    <img
      style={{ display: "inline-block" }}
      src={src}
      alt=""
      width={size}
      height={size}
    />
  );
}

export default function HitIcon({ hit, size = 24 }) {
  const { data: apps } = useSWR("/api/apps");

  if (!hit) {
    return null;
  }

  if (hit.iconCharacter) {
    return (
      <Typography style={{ fontSize: size }}>{hit.iconCharacter}</Typography>
    );
  }

  if (hit.icon) {
    return <HitIconImg src={hit.icon} size={size} />;
  }

  const app = apps?.find((app) => app.name === hit.source);

  if (!app || !app.defaultResultIcon) {
    return null;
  }

  return <HitIconImg src={app.defaultResultIcon} size={size} />;
}
